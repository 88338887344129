import React from "react";
import { SocialIcon } from 'react-social-icons'
import "../../src/App.css";

const Home = () => {
    return (
        <div>
            <div className='title dark-pink-text background-light-pink in-front'>
                <p className='title-border'>ABOUT US</p>
            </div>
            <div className="main dark-pink-text background-color-light-pink">
                <br/>
                <p className='header1 '>
                    FashStyle PH is a direct supplier and importer of brand new clothings and other fashion-related items from China (Shein, Patpat, Romwe, Bloomchic etc) but soon we will expand with new exciting products that suits your fashion.
                </p>

                <div className="center-text">
                <h1>Method of Payment</h1>

                𝐁𝐀𝐍𝐊 𝐓𝐑𝐀𝐍𝐒𝐅𝐄𝐑 <br/>
                    𝗕𝗣𝗜	<br/>
                    ᴀɴɢᴇʟɪᴄᴀ ᴀᴍʙᴜʟᴏ<br/>
                    4539380368 <br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("4539380368")}}>Copy</button> <br/><br/>

                    𝗕𝗗𝗢<br/>
                    ᴀɴɢᴇʟɪᴄᴀ ᴀᴍʙᴜʟᴏ<br/>
                    002371067055<br/>		
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("002371067055")}}>Copy</button> <br/><br/>

                    𝗦𝗘𝗖𝗨𝗥𝗜𝗧𝗬 𝗕𝗔𝗡𝗞<br/>
                    ᴀɴɢᴇʟɪᴄᴀ ᴀᴍʙᴜʟᴏ<br/>
                    0000063698528<br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("0000063698528")}}>Copy</button> <br/><br/>


                    𝐄-𝐖𝐀𝐋𝐋𝐄𝐓<br/>
                    𝗠𝗔𝗬𝗔<br/>
                    ᴀɴɢᴇʟɪᴄᴀ ᴀᴍʙᴜʟᴏ<br/>
                    09209786883<br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("09209786883")}}>Copy</button> <br/><br/>


                    𝗚𝗖𝗔𝗦𝗛<br/>
                    ᴀɴɢᴇʟɪᴄᴀ ᴀ.<br/>
                    09171995858<br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("09171995858")}}>Copy</button> <br/><br/>


                    ᴘᴀᴜʟ ᴀʟᴠɪɴ ᴀ.<br/>
                    09604456277<br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("09604456277")}}>Copy</button> <br/><br/>

                    ᴍᴀʀɪᴀ ᴛʜᴇʀᴇꜱᴀ ꜱ.<br/>
                    09209786883<br/>
                    <button  className="transparent-button" onClick={() => {navigator.clipboard.writeText("09209786883")}}>Copy</button> <br/><br/>

                </div>

                <p>
                    <hr />
                    For more updates, kindly add & follow us <br /> <br />
                    <div className="social-media"><a className='social-link' rel="noreferrer" href='https://www.facebook.com/fashstyledirectsupplier' target="_blank"><SocialIcon url="www.facebook.com" bgColor="#ad686d" className='social' /> FashStyle Shein Importer</a> <br /></div>
                    <div className="social-media"><a className='social-link' rel="noreferrer" href='https://www.facebook.com/PHFashstyle' target="_blank"><SocialIcon url="www.facebook.com" bgColor="#ad686d" className='social' /> FashStyle PH & Plus Posh Shop</a> <br /></div>
                    <div className="social-media"><a className='social-link' rel="noreferrer" href='https://www.facebook.com/groups/1050453209661668/' target="_blank"><SocialIcon url="www.facebook.com" bgColor="#ad686d" className='social' /> SHEIN Buy & Sell PH</a> <br /></div>
                    <div className="social-media"><a className='social-link' rel="noreferrer" href='https://www.tiktok.com/@fashstyleph' target="_blank"><SocialIcon url="www.tiktok.com" bgColor="#ad686d" className='social' /> FashStylePH </a> </div>
                </p>
            </div>
        </div>

    );
};

export default Home;;