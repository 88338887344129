import React from "react";
import { Twirl as Hamburger } from 'hamburger-react'

const Header = props => {
  const sidebarClass = props.isOpen;

  return (
    <header className="background-main-pink">
      <Hamburger color="#955361" toggled={sidebarClass} duration={0.5} onToggle={props.onClick} />
    </header>
  );
};

export default Header;
