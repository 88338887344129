import React from "react";
import "../../src/App.css";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const Installment = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div className='title dark-pink-text background-light-pink'>
                <p className='title-border'>Installment</p>
            </div>
            <div className="main dark-pink-text background-color-light-pink">
                <br />
                <form>

                    <label htmlFor="fname">Name:</label><br />
                    <input className="inputBorder" type="text" id="fname" name="fname" /><br />

                    <label htmlFor="lname">Address:</label><br />
                    <input className="inputBorder" type="text" id="lname" name="lname" /><br />

                    <label htmlFor="lname">Contact number:</label><br />
                    <input className="inputBorder" type="text" id="lname" name="lname" /><br />

                    <label htmlFor="lname">FB Page or Tiktok page name:</label><br />
                    <input className="inputBorder" type="text" id="lname" name="lname" /><br />

                    <label htmlFor="lname">Character ref:</label><br />
                    <input className="inputBorder" type="text" id="lname" name="lname" /><br />

                    <label htmlFor="lname">Contact number (Character ref):</label><br />
                    <input className="inputBorder" type="text" id="lname" name="lname" /><br /><br />

                    <label htmlFor="validId">Upload a valid ID:</label><br />
                    <input className="fileUpload" type="file" id="validId" name="validId" accept="image/png, image/jpeg"/> <br /><br />
                    
                    <label htmlFor="validId">Upload a selfie with valid ID:</label><br />
                    <input className="fileUpload" type="file" id="validId" name="validId" accept="image/png, image/jpeg"/> <br /><br />

                    <input className="inputButton" type="submit" value="Submit" /><br /> <br />
                    <div className="checkboxGroup">
                        <input type="checkbox" id="terms" name="terms" value="terms" />
                        <label htmlFor="terms" className='header1 '> &nbsp; Agree to the <a className="underline" onClick={handleShow}>terms and condition</a></label><br />
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>FashStylePH Terms and Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Acceptance of Terms: By accessing or using the Fashstyle PH website to purchase clothes, you agree to be bound by these terms and conditions.
                            <br /><br></br>
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;1. Product Information: Fashstyle PH makes every effort to display accurate and up-to-date information regarding the clothes we sell, including descriptions, sizing, and pricing. However, we do not warrant that product descriptions or other content on our site are accurate, complete, reliable, current, or error-free.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;2. Pricing and Payment: All prices for clothes are listed in Philippine Peso (PHP). Fashstyle PH reserves the right to change prices at any time without prior notice. Payment for installment must be made in the agreed amount at the time of ordering.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;3. Shipping and Delivery: Fashstyle PH will make every effort to ensure that your order is delivered within the estimated delivery timeframe. However, we are not liable for any delays in shipping or delivery.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;4. Returns and Refunds: If you are not satisfied with your purchase, you may return the item(s) within a specified period for a refund or exchange, subject to our return policy.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;5. Privacy Policy: Fashstyle PH is committed to protecting your privacy. Any personal information collected during the ordering process will be handled in accordance with our Privacy Policy.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;6. Intellectual Property: All content on the Fashstyle PH website, including logos, images, and text, is the property of Fashstyle PH and is protected by copyright laws. You may not use, reproduce, or distribute any content from our site without our prior written permission.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;7. Limitation of Liability: In no event shall Fashstyle PH be liable for any direct, indirect, punitive, incidental, special, or consequential damages, arising out of, or in any way connected with, the use of our website or the purchase of clothes.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;8. Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the Philippines, and you agree to submit to the exclusive jurisdiction of the courts in the Philippines for the resolution of any disputes.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;9. Changes to Terms: Fashstyle PH reserves the right to modify or revise these terms and conditions at any time. Any changes will be effective immediately upon posting on our website.
                            <br /><br />
                            &nbsp;	&nbsp;	&nbsp;	&nbsp;10. By making a purchase from the Fashstyle PH website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you have any questions about these terms, please contact us before placing your order.
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>


                </form>
            </div>
        </div>

    );
};

export default Installment;