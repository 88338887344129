import React from "react";
import { Link } from "react-router-dom";

const SideBar = props => {
    const sidebarClass = props.isOpen ? "sidebar open background-main-pink" : "sidebar background-main-pink";

    const handleClick = () =>{
        props.toggleSidebar()
    }

    return (
        <div className={sidebarClass}>
            <div className="sideNav background-main-pink">
                <Link to="/">
                    <button className="optionSide dark-pink-text" onClick={handleClick}> Home </button> <br/>
                </Link>
                <Link to="/installment">
                    <button className="optionSide dark-pink-text" onClick={handleClick}> Installment </button><br/>
                </Link>
                <Link to="/order">
                    <button className="optionSide dark-pink-text" onClick={handleClick}> Order </button><br/>
                </Link>
                <Link to="/">
                    <button className="optionSide dark-pink-text" onClick={handleClick}> Mode of Payment </button><br/>
                </Link>
                <Link to="/">

                    <button className="optionSide dark-pink-text" onClick={handleClick}> Feedback  </button><br/>
                </Link>
                            </div>
        </div>

    );
};
export default SideBar;