import React, { useState } from "react";
import HeaderNav from "./navs/Header";
import SideBar from "./navs/Sidebar";

import "../../src/App.css";

const Header = () => {
    const [sidebarOpen, setSideBarOpen] = useState(false);
    const handleViewSidebar = () => {
        setSideBarOpen(!sidebarOpen);
    };
    return (
        <div>
            <span>
                <HeaderNav isOpen={sidebarOpen} onClick={handleViewSidebar} />
            </span>
            <span>
                <SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
            </span>
        </div>

    );
};

export default Header;;