import './App.css';

import React from "react";
import Header from "./pages/Header";
import Installment from "./pages/Installment";
import Order from "./pages/Order";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/installment" element={<Installment />} />
        <Route exact path="/order" element={<Order />} />
      </Routes>
      <Header />
    </Router>
  );
}

export default App;