import React from "react";
import "../../src/App.css";
import { useState } from 'react';

const Order = () => {
    const [data, setData] = useState([{ order: "", qty: "" }])
    const [full_name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [mode, setMode] = useState("lalagrab");

    const handleClick = () => {
        setData([...data, { full_name: "", lname: "" }])
    }

    const handleDelete = (i) => {
        const deleteVal = [...data]
        deleteVal.splice(i, 1)
        setData(deleteVal)
    }

    const handleChange = (e, i) => {
        console.log(e.target)
        const { name, value } = e.target
        const onchangeVal = [...data]
        onchangeVal[i][name] = value
        setData(onchangeVal)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`The name you entered was: ${full_name}, ${address}, ${contact}, ${mode}, ${JSON.stringify(data)}`)
      }

    return (
        <div>

            <div className="main dark-pink-text background-color-light-pink">
                <br />
                <form onSubmit={handleSubmit}>

                    <label htmlFor="fname">Name:</label><br />
                    <input className="inputBorder" type="text" id="full_name" name="full_name" value={full_name} onChange={(e) => setName(e.target.value)}/><br />

                    <label htmlFor="lname">Address:</label><br />
                    <input className="inputBorder" type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)}/><br />

                    <label htmlFor="lname">Contact number:</label><br />
                    <input className="inputBorder" type="text" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)}/><br />

                    <label htmlFor="lname">Mode of delivery:</label><br />
                    <select className="inputBorder" name="delivery" id="cars" value={mode} onChange={(e) => setMode(e.target.value)}>
                        <option value="lalagrab" defaultChecked>Lalamove/Grab</option>
                        <option value="self">Self pick-up</option>
                        <option value="bus">Bus ship</option>
                        <option value="shopee">Shopee check out</option>
                    </select>

                    <label htmlFor="lname">Terms of payment:</label><br />
                    <select className="inputBorder" name="payment" id="cars">
                        <option value="cash">Cash Basis</option>
                        <option value="bus">CashInstall</option>
                        <option value="shopee">Installment</option>
                    </select>

                    <br /><br /><br />

                    <div className="orders">
                        {
                            data.map((val, i) =>
                                <div className="item">
                                    <label htmlFor="lname">Item Code:</label><br />
                                    <input className="inputBorder" type="text" id="order" name="order"  value={val.order} onChange={(e) => handleChange(e,i)} /><br />

                                    <label htmlFor="lname">Quantity:</label><br />
                                    <input className="inputBorder" type="number" id="qty" name="qty"  value={val.qty} onChange={(e) => handleChange(e,i)}/><br />
                                    <br />

                                    <button type="button" className="inputButton" name={i} onClick={() => handleDelete(i)}>Delete</button>
                                </div>
                            )
                        }

                    </div>
                    <button className="inputButton" type="button" onClick={handleClick}>Add</button>
                    <br /><br /><br />
                    <input className="inputButton" type="submit" value="Submit" /><br /><br />
                </form>

            </div>
        </div>

    );
};

export default Order;